import React from 'react';
// Import the InteractiveRubiksCube component
import InteractiveRubiksCube from './InteractiveRubiksCube';

const App = () => {
  // Comment out or remove previous functionality if needed
  /*
  const [showThreeDTree, setShowThreeDTree] = useState(true);
  const [showRandomCubes, setShowRandomCubes] = useState(false);

  const handleTreeClick = () => {
    setShowThreeDTree(false);
    setTimeout(() => {
      setShowRandomCubes(true);
    }, 2100);
  };
  */

  return (
    <div>
      {/* Render the InteractiveRubiksCube component */}
      <InteractiveRubiksCube />

      {/* Comment out previous components if you want to keep them for later */}
      {/*
        {showThreeDTree && <ThreeDTree onTreeClick={handleTreeClick} />}
        {showRandomCubes && <RandomCubes />}
      */}
    </div>
  );
};

export default App;
