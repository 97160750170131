import React, { useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import TWEEN from '@tweenjs/tween.js';

const InteractiveRubiksCube = () => {
  const mountRef = useRef(null);
  const cubeGroup = useRef(new THREE.Group());
  const [selectedCubie, setSelectedCubie] = useState(null);
  const raycaster = new THREE.Raycaster();
  const mouse = new THREE.Vector2();

  // Define the camera outside of useEffect
  const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
  camera.position.set(0, 0, 5);

  useEffect(() => {
    const scene = new THREE.Scene();

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    scene.add(cubeGroup.current);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    scene.add(ambientLight);
    const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
    directionalLight.position.set(0, 1, 1);
    scene.add(directionalLight);

    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.dampingFactor = 0.1;

    createCubes();
    renderer.domElement.addEventListener('click', onCanvasClick);

    const animate = () => {
      requestAnimationFrame(animate);
      TWEEN.update();
      if (!selectedCubie) {
        cubeGroup.current.rotation.x += 0.001;
        cubeGroup.current.rotation.y += 0.001;
      }
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      mountRef.current.removeChild(renderer.domElement);
      renderer.domElement.removeEventListener('click', onCanvasClick);
    };
  }, [selectedCubie]);

  const createCubes = () => {
    const cubeSize = 0.9;
    const geometry = new THREE.BoxGeometry(cubeSize, cubeSize, cubeSize);
    const material = new THREE.MeshBasicMaterial({
      color: 0x00ff00,
      transparent: true,
      opacity: 0.2
    });
    const edgesMaterial = new THREE.LineBasicMaterial({ color: 0x000000 });
  
    for (let x = 0; x < 3; x++) {
      for (let y = 0; y < 3; y++) {
        for (let z = 0; z < 3; z++) {
          if (x === 1 && y === 1 && z === 1) continue;
  
          const cubeMesh = new THREE.Mesh(geometry, material);
          cubeMesh.position.set(x - 1, y - 1, z - 1);
          const edgesGeometry = new THREE.EdgesGeometry(cubeMesh.geometry);
          const cubeEdges = new THREE.LineSegments(edgesGeometry, edgesMaterial);
          cubeMesh.add(cubeEdges);
  
          // Assign setSelectedCubie directly to onClick
          cubeMesh.userData.onClick = () => setSelectedCubie(cubeMesh);
          cubeGroup.current.add(cubeMesh);
        }
      }
    }
  };

  const onCanvasClick = (event) => {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
  
    const intersects = raycaster.intersectObjects(cubeGroup.current.children, true);
    console.log("Intersects:", intersects); // Check if any intersects are found
  
    if (intersects.length > 0) {
      const clickedCubie = intersects[0].object;
      console.log("Clicked Cubie:", clickedCubie); // Log the clicked cubie
  
      if (clickedCubie.userData.onClick) {
        console.log("Calling onClick for clicked cubie");
        animateCubieSelection(clickedCubie);
        clickedCubie.userData.onClick(); // Call the onClick function if it exists
      }
    }
  };

  const animateCubieSelection = (cubie) => {
    cubeGroup.current.children.forEach(cube => {
      if (cube !== cubie) {
        new TWEEN.Tween(cube.scale)
          .to({ x: 0.1, y: 0.1, z: 0.1 }, 500)
          .easing(TWEEN.Easing.Quadratic.Out)
          .start();
      }
    });

    new TWEEN.Tween(cubie.position)
      .to({ z: 2 }, 500)
      .easing(TWEEN.Easing.Quadratic.Out)
      .start();
  };

  return <div ref={mountRef} style={{ height: '100vh', width: '100vw' }} />;
};

export default InteractiveRubiksCube;
